import React, { useState } from 'react'
import Layout from '../../../components/layout'
import { Container, Row, Col, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import styled from 'styled-components'
import Banner from '../../../images/blog-banner.jpg'
import { FiArrowRight, } from 'react-icons/fi';







const Index = (props) => {

    const [modal, setModal] = useState(false);
    //   const [nestedModal, setNestedModal] = useState(false);
    //   const [closeAll, setCloseAll] = useState(false);
    const toggle = () => setModal(!modal);
    const shareUrl = 'https://www.tridentconsultinginc.com/Company/Blog/Diversity/';
    const title = 'Five Ways Diverse Organizations Hire Differently';
    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const { className } = props;

    return (
        <Layout path="/blog/">
            <section className="banner_min">
                <div className="banner_section">
                    {/* <Img fluid={Banner} ></Img> */}
                    <img src={Banner} alt="img" />
                </div>

            </section>

            <section className="blog_section">
                <Container>
                    <Row>
                        <Col md="6">
                            <div className="main_blog_card">
                                <a href="/Company/Blog/Diversity">
                                    <div className="main_blog">
                                        <h3>Five Ways Diverse Organizations Hire Differently</h3>
                                        <h5><span> By</span> <a href="/Company/AboutCEO">Shabana Siraj</a></h5>
                                        <p>The business case for diversity is well documented in terms of how it enables companies to exceed their financial goals while successfully navigating their social responsibilities as organizations...</p>
                                        <FiArrowRight size={30} color="#5d6d84" className="arrow" />
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="main_blog_card">
                                <a href="/Company/Blog/Connect">
                                    <div className="main_blog">
                                        <h3>Ideas for Managers to engage with their teams</h3>
                                        <h5><span> By</span> <a href="/Company/AboutCEO">Shabana Siraj</a></h5>
                                        <p>A year and three months since the pandemic was officially declared, countries are still recovering from the onslaught. With vaccinations increasingly administered however, life and the economy have started getting back to a ‘newer’ normal... </p>
                                        <FiArrowRight size={30} color="#5d6d84" className="arrow" />
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}
const StyledBackgroundSection = styled(Index)`
      width: 100%;
      background-position: bottom center;
      background-repeat: repeat-y;
      background-size: cover;
      margin: 0;
      background-color: inherit !important;
      
      `

export default StyledBackgroundSection